const axios = require('axios')

async function fetchJobListings() {
    try {
        const results = await axios.get('https://boards-api.greenhouse.io/v1/boards/blokhaus/jobs')
        return results
    } catch (err) {
        console.log(err)
    }
}

module.exports = {
  fetchJobListings
}